<template>
  <window-content v-if="!preventBind" class="financeiro-window">
    <div class="w-m-header">
      <pessoa-cadastro ref="cadastro" :mount-body="true" style="z-index: 11000" />
      <div>
        <h2>Cliente</h2>
      </div>
      <button @click="$uloc.window.close($root.wid)" type="button" class="btn-close">
        <i class="fal fa-times"/>
        <u-tooltip content-class="bg-indigo" :offset="[5, 5]">Fechar</u-tooltip>
      </button>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1 fin-form">
      <div class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Detalhes do cliente</legend>
          </e-col>
          <e-col v-if="cliente.id" class="flex items-center justify-end m-r" style="max-width: 220px; min-height: 32px">
            ID #{{cliente.id}}
          </e-col>
          <e-col class="flex items-center justify-end" style="max-width: 220px; min-height: 32px">
            <status-select size="2" label="Status:" placeholder="Selecione" field-view="lr" simple-border=""
                           v-model="cliente.status"/>
          </e-col>
        </e-row>
        <e-row>
          <e-col>
            <e-row mr>
              <e-col>
                <erp-s-field label="Pessoa relacionada">
                  <person-select v-model="cliente.pessoa"/>
                  <div class="font-12 m-t-xs" v-if="cliente.pessoa"><a @click="gotoPerson(cliente.pessoa)">Visualizar pessoa</a></div>
                  <div class="font-12 m-t-xs" v-if="cliente.pessoa"><a @click="editPessoa(cliente.pessoa.id)">Editar pessoa</a></div>
                  <div class="m-t-xs text-right font-11" v-else><a @click="$refs.cadastro.show()">Cadastrar pessoa</a></div>
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field required label="Domínio">
                  <erp-input placeholder="Nome do domínio" v-model="cliente.dominio" required autofocus/>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row mr>
            </e-row>
            <e-row mr>
              <e-col>
                <erp-s-field label="Descrição">
                  <textarea placeholder="Observações sobre o cliente" v-model="cliente.observacao" class="sl-textarea"
                            rows="8"/>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row mr>
              <e-col>
                <erp-s-field label="Descrição sobre os serviços">
                  <textarea placeholder="Como deve sair na fatura" v-model="cliente.descricaoServicos" class="sl-textarea"
                            rows="4"/>
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field label="Descrição para a nota">
                  <textarea placeholder="Como deve sair na nota fiscal" v-model="cliente.descricaoNota" class="sl-textarea"
                            rows="4"/>
                </erp-s-field>
              </e-col>
            </e-row>
          </e-col>
        </e-row>
      </div>
      <div class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Plano</legend>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col style="min-width: 23%"><plano-select v-model="cliente.plano" /></e-col>
          <e-col style="min-width: 20%"><servidor-select v-model="cliente.servidor" /></e-col>
          <e-col style="max-width: 300px">
            <erp-s-field label="Data de Início do Contrato">
              <erp-input size="2" v-mask="'##/##/####'" simple-border v-model.lazy="cliente.contratoInicio"/>
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 300px">
            <erp-s-field label="Contrato Assinado?">
              <erp-select :options="[
                  {label: 'Não', value: false},
                  {label: 'Sim', value: true}
              ]" v-model="cliente.contratoAssinado" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field
                label="Mensalidade:"
            >
              <erp-input size="2" v-money="money" simple-border v-model.lazy="cliente.mensalidade" style="background-color: #eeffee"/>
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field
                label="Meta Mensal:"
            >
              <erp-input size="2" v-money="money" simple-border v-model.lazy="cliente.metaMensal" style="background-color: #f2f2f2"/>
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Periodicidade">
              <erp-select :options="repetirTypes" v-model="cliente.mensalidadePeriodicidade" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Dia de vencimento">
              <erp-input size="2" simple-border v-model.lazy="cliente.mensalidadeDiaVencimento"/>
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Próximo de vencimento">
              <erp-input size="2" v-mask="'##/##/####'" simple-border v-model.lazy="cliente.mensalidadeProximoVencimento"/>
            </erp-s-field>
          </e-col>
        </e-row>
      </div>
      <div class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Definições</legend>
          </e-col>
        </e-row>
        <collapse :collapse="false" title="API">
          <e-row mr>
            <e-col>
              <erp-s-field label="API Key">
                <erp-input readonly size="2" simple-border v-model.lazy="cliente.apiKey"/>
              </erp-s-field>
            </e-col>
            <e-col>
              <erp-s-field label="API Secret">
                <erp-input readonly size="2" simple-border v-model.lazy="cliente.apiSecret"/>
              </erp-s-field>
            </e-col>
          </e-row>
        </collapse>
        <collapse :collapse="false" title="Instância">
          <e-row mr>
            <e-col style="min-width: 20%"><servidor-select v-model="cliente.servidor" /></e-col>
          </e-row>
          <e-row mr>
            <e-col>
              <erp-s-field label="Conexão Banco de Dados">
                <erp-input size="2" simple-border v-model.lazy="cliente.configuracao.databaseUrl"/>
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row class="m-t m-b-sm" mr>
            Aliases
          </e-row>
          <div>
            <e-row v-for="(alias, key) in cliente.configuracao.aliases" :key="'alias-' + key" mr>
              <e-col>
                <erp-s-field label="Domínio">
                  <erp-input size="2" simple-border v-model.lazy="alias.dominio"/>
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field label="Conexão Banco de Dados">
                  <erp-input size="2" simple-border v-model.lazy="alias.databaseUrl"/>
                </erp-s-field>
              </e-col>
              <e-col style="max-width: 50px; display: flex; align-items: flex-end">
                <u-btn @click="removerAlias(a)" label="Remover" icon-type="fa" icon="times" flat no-caps round size="sm" />
              </e-col>
            </e-row>
          </div>
          <div class="m-b">
            <u-btn @click="adicionarAlias" no-caps color="white" label="Adicionar alias" class="bg-grey b-radius-3px" />
          </div>
          <e-row class="wrapper-sm" mr>
            <e-col>
              <erp-s-field label="Dasativar avisos de cobrança">
                <sl-checkbox v-model="cliente.configuracao.desativarAvisosCobrancas" />
              </erp-s-field>
            </e-col>
          </e-row>
        </collapse>
      </div>
    </div>
    <div class="w-m-footer text-right footer-modern window-actions">
      <div class="text-left">
        <u-btn @click="$uloc.window.close($root.wid)" label="Voltar"
               class="bg-blue-grey-1 text-blue" no-caps/>
      </div>
      <div class="text-right">
        <u-btn :loading="loading" type="button" @click="save('andClose')" label="Salvar Projeto" no-caps
               :color="'green'"
               class="b-radius-3px no-border-radius-right__"/>
        <!--        <u-btn icon="sort-down" icon-type="fa" no-caps class="p-btn-down normal b-radius-3px font-12 text-white">
                  <u-popover ref="popover" class="window-context-menu" style="width: 192px" anchor="bottom right"
                             self="top right" :offset="[0, 0]">
                    <div>
                      <ul>
                        <menu-options-item @click="save('andEdit')" label="Salvar e continuar editando" close/>
                        <menu-options-item @click="save('andNew')" label="Salvar e criar novo" close/>
                      </ul>
                    </div>
                  </u-popover>
                </u-btn>-->
      </div>
    </div>
  </window-content>
</template>

<script>
import {UTooltip, UPopover, debounce} from "uloc-vue"
import ERow from '@/components/layout/components/Row'
import {ErpInput, ErpSField, ErpSelect, WindowContent} from 'uloc-vue-plugin-erp'
import ECol from '@/components/layout/components/Col'
import {newCliente, updateCliente, find} from "@/domain/gerencia/clientes/services"
import {convertRealToMoney, REAL_BRL} from "@/utils/money"
import {VMoney} from "v-money"
// import SlCheckbox from "@/reuse/input/Checkbox"
import PersonSelect from "components/suporte/components/projetos/include/PersonSelect"
import StatusSelect from "components/gerencia/cliente/components/include/StatusClienteSelect"
import PlanoSelect from "components/clientes/components/cliente/include/PlanoSelect"
import ServidorSelect from "components/clientes/components/cliente/include/ServidorSelect"
import PessoaCadastro from '@/components/pessoa/components/window/Cadastro'
import {REPETIR} from "@/domain/gerencia/clientes/helpers/periodicidade"
import {datePtToEn} from "@/utils/date"
import Collapse from "components/globalconfig/components/include/Collapse";
import GlobalPersonMixin from "components/pessoa/globalPersonMixin";
import SlCheckbox from "@/reuse/input/Checkbox.vue";

let mockCliente = {
  id: null,
  dominio: null,
  configuracao: {
    instancia: null,
    databaseUrl: null,
    aliases: [],
    desativarAvisosCobrancas: false,
  },
  configuracaoString: '',
  observacao: null,
  apiKey: null,
  apiSecret: null,
  descricaoServicos: null,
  descricaoNota: null,
  setup: null,
  metaMensal: null,
  mensalidade: null,
  mensalidadePeriodicidade: null,
  mensalidadeDiaVencimento: null,
  mensalidadeProximoVencimento: null,
  contratoInicio: null,
  contratoAssinado: false,
  plano: null,
  servidor: null,
  pessoa: null,
  enviarBoasVindas: true,
  solicitarCriacaoSenha: true,
  status: 'active',
  statusMessage: null,
  active: true
}

export default {
  name: 'ProjetoWindow',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [GlobalPersonMixin],
  directives: {money: VMoney},
  components: {
    SlCheckbox,
    PessoaCadastro,
    PlanoSelect,
    ErpSelect,
    PersonSelect,
    // SlCheckbox,
    // DatetimeInput,
    ErpInput,
    ErpSField,
    ERow,
    ECol,
    // ErpSelect,
    UTooltip,
    //MenuOptionsItem,
    // UPopover,
    WindowContent,
    StatusSelect,
    ServidorSelect,
    Collapse
  },
  props: ['id', 'options', 'router'],
  data() {
    return {
      money: REAL_BRL,
      percent: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      },
      loading: false,
      editing: false,
      success: false,
      status: '',
      preventBind: true,
      cliente: JSON.parse(JSON.stringify(mockCliente))
    }
  },
  mounted() {
    if (this.id) {
      this.load()
    } else {
      this.preventBind = false
    }
  },
  watch: {},
  computed: {
    repetirTypes() {
      return Object.keys(REPETIR).map(k => {
        return {
          label: REPETIR[k].title,
          value: Number(k)
        }
      })
    }
  },
  methods: {
    load() {
      this.loading = true
      find(this.id)
          .then(response => {
            console.log(response)
            if (response.data.id) {
              this.cliente.id = response.data.id
            }
            this.preventBind = true
            this.cliente = Object.assign({}, this.cliente, this.prepareToEdit(response.data))
            this.$nextTick(() => {
              setTimeout(() => {
                this.preventBind = false
              }, 1)
            })
            console.log(this.cliente)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    prepareToSave() {
      const data = JSON.parse(JSON.stringify(this.cliente))
      const valores = ['mensalidade', 'setup', 'metaMensal']
      valores.map(key => {
        data[key] = convertRealToMoney(data[key])
        if (!data[key]) {
          data[key] = null
        }
      })

      const relacoes = ['pessoa', 'plano', 'servidor', 'gerente']
      relacoes.map(key => {
        if (data[key] && data[key].id) {
          data[key] = data[key].id
        }
      })

      const datas = ['mensalidadeProximoVencimento', 'contratoInicio']
      datas.map(key => {
        if (data[key]) {
          data[key] = datePtToEn(data[key])
        }
      })
      try {
        //data.configuracao = this.$refs.configEditor.innerText
        data.configuracao = JSON.stringify(data.configuracao)
      } catch (e) {
        alert('Configurações inválida')
        return
      }
      return data
    },
    prepareToEdit(data) {
      const valores = ['mensalidade', 'setup', 'metaMensal']
      valores.map(key => {
        data[key] = this.$options.filters.moeda(data[key])
        if (!data[key]) {
          data[key] = null
        }
      })
      const ids = ['plano', 'servidor']
      ids.map(key => {
        if (data[key] && data[key].id) {
          data[key] = data[key].id
        }
      })
      const datas = ['mensalidadeProximoVencimento', 'contratoInicio']
      datas.map(key => {
        if (data[key] && data[key].date) {
          data[key] = this.$options.filters.formatDate(data[key].date)
        }
      })
      if (typeof data.configuracao !== 'object' || Array.isArray(data.configuracao)) {
        data.configuracao = JSON.parse(JSON.stringify(mockCliente.configuracao))
      }

      if (!data.configuracao.aliases || !Array.isArray(data.configuracao.aliases)) {
        data.configuracao.aliases = []
      }

      data.configuracaoString = JSON.stringify(data.configuracao, null, 4)
      return data
    },
    async save(type = 'andClose') {
      this.loading = true
      let data
      try {
        data = this.prepareToSave()
      } catch (e) {
        console.log(e)
        this.alertApiError(null, 'Erro de validação', null, `${e.message} Código L-${e.lineNumber}`)
        this.loading = false
        return
      }
      const submit = () => {
        let method = newCliente
        if (this.cliente.id) {
          method = (data) => updateCliente(data.id, data)
        }
        method(data)
            .then(response => {
              this.loading = false
              console.log(response)
              const isEditing = this.isEditing
              this.cliente.id = response.data.id
              // this.$uloc.window.close(this.$root.wid)
              if (this.id) {
                this.$uloc.window.emit(this.$root.wid, 'updated', response.data)
                this.success = true
                this.dg()
                this.$uloc.notify({
                  color: 'positive',
                  message: `Cliente com ID #${response.data.id} alterado.`,
                  position: 'bottom-left',
                  /*actions: [{
                    label: 'Visualizar',
                    handler: () => {
                      this.gotoPerson(response.data)
                    }
                  }]*/
                })
              } else {
                this.$uloc.window.emit(this.$root.wid, 'created', response.data)
                this.$uloc.notify({
                  color: 'black',
                  message: `Cliente com ID #${response.data.id} ${this.id ? 'modificado' : 'criado'}.`,
                  position: 'bottom-left',
                  /*actions: [{
                    label: 'Visualizar',
                    handler: () => {
                      this.gotoPerson(response.data)
                    }
                  }]*/
                })
              }
              if ((!this.$refs.uploader || (this.$refs.uploader && !this.$refs.uploader.existsFilesToUpload)) && (type === 'andClose' || (this.entidade && this.entidadeId))) {
                // this.$uloc.window.close(this.$root.wid)
              } else if (type === 'andNew') {
                this.preventBind = true
                this.cliente = JSON.parse(JSON.stringify(mockCliente))
                this.$nextTick(() => {
                  this.preventBind = false
                })
              }
            })
            .catch(error => {
              console.log(error)
              this.loading = false
              this.alertApiError(error)
            })
      }
      submit()
    },
    editPessoa(id) {
      this.$refs.cadastro.show(id)
    },
    adicionarAlias () {
      if (!this.cliente.configuracao || !Array.isArray(this.cliente.configuracao.aliases)) {
        this.cliente.configuracao.aliases = []
      }
      this.cliente.configuracao.aliases.push({
        dominio: null,
        databaseUrl: null
      })
    },
    removerAlias (a) {
      this.cliente.configuracao.aliases.splice(this.cliente.configuracao.aliases.indexOf(a), 1)
    }
  }
}
</script>
